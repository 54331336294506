import { FormatUnderlined } from "@material-ui/icons";
import { defaultFont } from "assets/jss/material-kit-react.js";

import tooltip from "assets/jss/material-kit-react/tooltipsStyle.js";

const headerLinksStyle = (theme) => ({
  list: {
    ...defaultFont,
    fontSize: "14px",
    margin: 0,
    paddingLeft: "0",
    listStyle: "none",
    paddingTop: "0",
    paddingBottom: "0",
    color: "inherit",
    display: "flex",
    alignItems: "center",
  },
  listItem: {
    float: "left",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "#e5e5e5",
      },
    },
  },
  listItemDropdown: {
    float: "right",
    color: "inherit",
    position: "relative",
    display: "block",
    width: "auto",
    margin: "0",
    padding: "0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "&:after": {
        width: "calc(100% - 30px)",
        content: '""',
        display: "block",
        height: "1px",
        marginLeft: "15px",
        backgroundColor: "#e5e5e5",
      },
    },
  },
  listItemText: {
    padding: "0 !important",
  },
  navLink: {
    position: "relative",
    padding: "1.3rem",
    textTransform: "inherit",
    borderRadius: "3px",
    margin: "0px",
    color: "white",
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
  },
  link: {
    color: "white",
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "Poppins",
    textTransform: "inherit",
    lineHeight: "20px",
    "&:hover,&:focus": {
      color: "#00b0d5",
    },
  },
  linkActive: {
    color: "white",
    fontWeight: "400",
    fontSize: "14px",
    fontFamily: "Poppins",
    textTransform: "inherit",
    lineHeight: "20px",
    borderBottom: "1px solid #00b0d5",
    "&:hover,&:focus": {
      color: "#00b0d5",
    },
  },
  loginRegister: {
    padding: 0,
  },
  loginRegisterLink: {
    borderLeft: "1px solid #c1c1c1",
    borderRight: "1px solid #c1c1c1",
    color: "#c1c1c1",
    padding: "0 0.9375rem",
    "&:hover,&:focus": {
      color: "#00b0d5",
    },
  },
  registerLink: {
    borderLeft: "0",
    marginRight: "10px",
  },
  accesoEmpresas: {
    backgroundColor: "#ebebeb",
    "&:hover": {
      backgroundColor: "rgba(231,81,105,0.4)",
    },
  },
  accesoEmpresaLink: {
    textAlign: "right",
  },
  notificationNavLink: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    top: "4px",
  },
  registerNavLink: {
    top: "3px",
    position: "relative",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
  },
  icons: {
    width: "20px",
    height: "20px",
    marginRight: "3px",
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px",
  },
  dropdownLink: {
    "&,&:hover,&:focus": {
      color: "inherit",
      textDecoration: "none",
      display: "block",
      padding: "10px 20px",
    },
  },
  ...tooltip,
  marginRight5: {
    marginRight: "5px",
  },
  "@media screen and (max-width: 960px)": {
    list: {
      flexDirection: "column",
    },
    loginRegister: {
      padding: "1.3rem",
    },
    loginRegisterLink: {
      padding: "0",
      border: "none",
    },
    accesoEmpresasLink: {
      textAlign: "left !important",
      width: "100%",
    },
    accesoEmpresaLink: {
      textAlign: "left",
    },
  },
});

export default headerLinksStyle;
