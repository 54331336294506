import loginAdminImg from "assets/img/admin_img.jpg";
import iconImg from "assets/img/logo_header.png";
import sideBarImg from "assets/img/sidebar-3.jpg";

var params = [];
params["baseUrl"] = "https://ucosia.aicor.es/";
params["apiUrl"] = "https://api.ucosia.aicor.es/api";
params["urlBackend"] = "https://api.ucosia.aicor.es/storage";
params["loginAdminImg"] = loginAdminImg;
params["sideBarIcon"] = iconImg;
params["sideBarImg"] = sideBarImg;
params["sideBarColor"] = "#93c47d";
params["firstColorRGB"] = "#93c47d";
params["firstColor"] = "#93c47d";
params["firstColorHover"] = "#A9ECF9";
params["secondColor"] = "#93c47d";
params["color_green"] = "#00C534";
params["color_red"] = "#FF0C56";
params["color_grey"] = "#BBBBBB";
params["proyectName"] = "UCO SIA";
params["defaultTableLength"] = 10;
params["tableLengthArray"] = [10, 25, 50];
params["personalizacion"] = false;
params["dev_mode"] = true;
params["panel_url"] = "administrador";
params["web_url"] = "entidad";

export default params;
