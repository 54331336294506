import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import CustomLoading from "components/CustomLoading/CustomLoading";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Datatable from "components/Table/Datatable";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PARAMS from "utils/PARAMS";
import Actions from "components/Actions/Actions";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "components/Modal/Modal";
import { Alert, AlertTitle } from "@material-ui/lab";
import Select from "@material-ui/core/Select";
import {
  buscadorMuestras,
  getGrupos,
  getTipos,
  exportarMuestras,
  exportarMultiplesIds,
  isAdminAuth,
} from "utils/API_V2";
import DetalleMuestra from "../DetalleMuestra/DetalleMuestra";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import moment from "moment";
import { checkObject } from "utils/VALIDATION";
import ClearIcon from "@material-ui/icons/Clear";
import PageviewIcon from "@material-ui/icons/Pageview";
import { read, writeFileXLSX } from "xlsx";
import * as FileSaver from "file-saver";
/* load the codepage support library for extended support with older formats  */
import { set_cptable } from "xlsx";

import ImportExportIcon from "@material-ui/icons/ImportExport";
import * as cptable from "xlsx/dist/cpexcel.full.mjs";
import {
  descargarMuestra,
  bloquearMuestra,
  desbloquearMuestra,
} from "utils/API_V2";

set_cptable(cptable);

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    // display: "none",
  },
}));

const Buscador = () => {
  const [openDeshabilitar, setOpenDeshabilitar] = useState(false);
  const [openHabilitar, setOpenHabilitar] = useState(false);
  const classes = useStyles();
  const [isLoad, setIsLoad] = useState(false);
  const [muestras, setMuestras] = useState([]);
  const [findBy, setFindBy] = useState("");
  const [page, setPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [muestra, setMuestra] = useState({});
  const [actualPage, setActualPage] = useState(1);
  const [grupos, setGrupos] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [activarFiltro, setActivarFiltro] = useState(false);
  const [rol, setRol] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [contadorFilasSeleccionadas, setContadorFilasSeleccionadas] = useState(
    0
  );
  let history = useHistory();

  const handleClose = () => {
    setOpenModal(false);
    setOpenLogin(false);
    setOpenDeshabilitar(false);
    setOpenHabilitar(false);
  };

  const handleRowSelected = (params) => {
    setContadorFilasSeleccionadas(params.selectedCount);
    setSelectedRows(params.selectedRows);
  };

  const handleFiltroNomLatino = (e) => {
    setFiltros({ ...filtros, activarFiltro: e });
  };

  function handleClick(path) {
    history.push(path);
  }

  const defaultFiltros = {
    tipo: 1,
    grupo: 15,
    activarFiltro: false,
    inicio_fecha_recogida: moment(new Date()).format("YYYY-MM-DD"),
    final_fecha_recogida: moment(new Date()).format("YYYY-MM-DD"),
  };

  const [filtros, setFiltros] = useState(defaultFiltros);

  useEffect(() => {
    obtenerGrupos();
    obtenerTipos();
    if (localStorage.getItem("apiToken")) {
      obtenerRol();
    }
    obtenerMuestras(findBy, page, perPageData, filtros);
  }, []);

  async function obtenerRol() {
    const res = await isAdminAuth();
    if (res.error) {
      toast("Error al intentar obtener el rol", { type: "error" });
    } else {
      setRol(res.data.rol);
    }
  }

  useEffect(() => {
    obtenerMuestras(findBy, page, perPageData, filtros);
  }, [filtros]);

  useEffect(() => {
    obtenerMuestras(findBy, actualPage, perPageData, filtros);
  }, [perPageData, actualPage]);

  const handleSearch = (findBy) => {
    obtenerMuestras(findBy, page, perPageData, filtros);
  };

  async function viewPdf(muestraId) {
    if (!localStorage.getItem("apiToken")) {
      setOpenLogin(true);
    } else {
      const res = await descargarMuestra(muestraId, filtros);

      if (res.error) {
        toast("Error al intentar descargar pdf", { type: "error" });
      } else {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/pdf;base64" })
        );
        window.open(url, "_blank");
      }
    }
  }

  const columnNames = [
    // { name: "Ids", key: "id" },
    { name: "Nombre", key: "nombre" },
    { name: "Nombre Latino", key: "nombre_latino", width: "200px" },
    { name: "Observaciones", key: "observaciones" },
    { name: "Fecha Recogida", key: "fecha_recogida" },
    { name: "Acciones", key: "acciones", width: "300px", sortable: false },
  ];

  const validate_fields = {
    grupo: { type: "NULL", field: "Grupo" },
    tipo: { type: "NULL", field: "Tipo" },
    inicio_fecha_recogida: { type: "NULL", field: "Inicio Fecha Recogida" },
    final_fecha_recogida: { type: "NULL", field: "Final Fecha Recogida" },
  };

  const restringirMuestra = (muestra) => {
    setMuestra(muestra);
    muestra?.restringida ? setOpenHabilitar(true) : setOpenDeshabilitar(true);
  };

  function createData(
    // id,
    nombre,
    nombre_latino,
    observaciones,
    fecha_recogida,
    acciones
  ) {
    return {
      // id,
      nombre,
      nombre_latino,
      observaciones,
      fecha_recogida,
      acciones,
    };
  }

  async function obtenerMuestras(findBy, page, perPageData, filtros) {
    setIsLoad(false);
    const res = await buscadorMuestras(findBy, page, perPageData, filtros);
    if (res.error) {
      toast("Se ha producido un error en la carga de muestras", {
        type: "warning",
      });
    } else {
      const arrayMuestras = [];
      if (res.data.data.length > 0) {
        res.data.data.forEach((muestra, index) => {
          let aux = createData(
            // muestra.id,
            muestra.nombre,
            muestra.nombre_latino,
            muestra.observaciones,
            moment(muestra?.fecha_recogida).format("DD/MM/YYYY"),
            <div>
              <Actions
                show={true}
                onShow={() => loadDetailMuestra(muestra)}
                exportFile={true}
                onExportFile={() => exportarArchivo(muestra.id)}
                // pdf={true}
                // onViewPdf={() => viewPdf(muestra.id)}
                restrict={true}
                rol={rol}
                habilitar={muestra?.restringida ? true : false}
                onRestrict={() => restringirMuestra(muestra)}
              />
            </div>
          );

          arrayMuestras.push(aux);
        });
      }

      if (res.data.meta.total < selectedRows.length) {
        setContadorFilasSeleccionadas(0);
      }

      setTotalData(res.data.meta.total);
      setMuestras(arrayMuestras);
      setIsLoad(true);
    }
  }

  async function obtenerGrupos() {
    const res = await getGrupos();
    if (res.error) {
      toast("Error", { type: "error" });
    } else {
      console.log(res.data);
      setGrupos(res.data);
    }
  }

  async function obtenerTipos() {
    const res = await getTipos();
    if (res.error) {
      toast("Error", { type: "error" });
    } else {
      console.log(res.data);
      setTipos(res.data);
    }
  }

  async function exportarArchivo(muestraId) {
    if (!localStorage.getItem("apiToken")) {
      setOpenLogin(true);
    } else {
      const res = await exportarMuestras(muestraId);

      if (res.error) {
        toast("Error, al intentar exportar archivo", { type: "error" });
      } else {
        let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });

        FileSaver.saveAs(blob, "Exportar.xlsx");
      }
    }
  }

  async function exportMultiplesMuestras(selectedRows) {
    if (!localStorage.getItem("apiToken")) {
      setOpenLogin(true);
    } else {
      const res = await exportarMultiplesIds(selectedRows);
      if (res.error) {
        toast("Error al intentar exportar las muestras", { type: "error" });
      } else {
        let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });

        FileSaver.saveAs(blob, "Exportar.xlsx");
      }
    }
  }

  const getIdsMuestras = (selectedRows) => {
    let auxArray = [];
    selectedRows.forEach((element) => {
      auxArray.push(element.id);
    });
    return auxArray;
  };

  async function exportarArchivos() {
    let auxArrayIdS = getIdsMuestras(selectedRows);
    exportMultiplesMuestras(auxArrayIdS);
  }

  const loadDetailMuestra = (muestra) => {
    var validate = checkObject(validate_fields, filtros);

    if (validate.status) {
      let auxFiltros = {
        grupo_analisis: filtros.grupo,
        tipos_analisis: filtros.tipo,
        fecha_inicio_recogida: moment(filtros?.inicio_fecha_recogida).format(
          "DD/MM/YYYY"
        ),
        fecha_final_recogida: moment(filtros?.final_fecha_recogida).format(
          "DD/MM/YYYY"
        ),
        muestras_seleccionadas: [
          muestra.nombre + " | " + muestra.nombre_latino,
        ],
      };
      localStorage.setItem("filtrosAplicados", JSON.stringify(auxFiltros));

      history.push(`/detalle-muestra/${muestra.nombre_latino}`);
    } else {
      toast(validate.message, { type: "warning" });
    }
  };

  const formatearMuestrasFilas = (selectedRows) => {
    let auxArray = [];
    selectedRows.forEach((element) => {
      auxArray.push(element.nombre + " | " + element.nombre_latino);
    });

    return auxArray;
  };

  async function deshabilitarMuestra() {
    const res = await bloquearMuestra(muestra.id);

    if (res.error) {
      toast(res.error, { type: "warning" });
    } else {
      toast(res, { type: "success" });
      handleClose();
      obtenerMuestras(findBy, page, perPageData, filtros);
    }
  }

  async function habilitarMuestra() {
    const res = await desbloquearMuestra(muestra.id);

    if (res.error) {
      toast(res.error, { type: "warning" });
    } else {
      toast(res, { type: "success" });
      handleClose();
      obtenerMuestras(findBy, page, perPageData, filtros);
    }
  }

  const formatearMuestrasFilasIds = (selectedRows) => {
    let auxArray = [];
    selectedRows.forEach((element) => {
      auxArray.push(element.id);
    });

    return auxArray;
  };

  const alertLogin = () => {
    return (
      <Alert severity="warning" style={{ width: "100%" }}>
        <AlertTitle>Atención</AlertTitle>
        Para descargarte estos datos debe de iniciar sesión —{" "}
        <strong
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/login")}
        >
          Puede iniciar sesión aquí{" "}
        </strong>
      </Alert>
    );
  };

  const loadDetailsMuestras = () => {
    var validate = checkObject(validate_fields, filtros);

    let auxFilasSeleccionadas = formatearMuestrasFilas(selectedRows);
    let idsMuestras = formatearMuestrasFilasIds(selectedRows);
    if (validate.status) {
      let auxFiltros = {
        grupo_analisis: filtros.grupo,
        tipos_analisis: filtros.tipo,
        fecha_inicio_recogida: moment(filtros?.inicio_fecha_recogida).format(
          "DD/MM/YYYY"
        ),
        fecha_final_recogida: moment(filtros?.final_fecha_recogida).format(
          "DD/MM/YYYY"
        ),
        muestras_seleccionadas: auxFilasSeleccionadas,
        muestras_ids: idsMuestras,
      };
      localStorage.setItem("filtrosAplicados", JSON.stringify(auxFiltros));
      history.push(`/detalles-muestras/`);
    } else {
      toast(validate.message, { type: "warning" });
    }
    console.log({ selectedRows });
  };

  return (
    <>
      <GridContainer xs={12} sm={12} md={12} lg={12} xl={12}>
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Alert severity="info">
            <AlertTitle>
              <strong>COMO SELECCIONAR MUESTRAS:</strong>
            </AlertTitle>
            <strong> 1) UNA SOLA MUESTRA:</strong> Hacer click directamente
            sobre el botón
            <br></br>
            <strong> 2) VARIAS MUESTRAS:</strong> Seleccionar varias muestras
            mediante checkbox y hacer click en el botón consultar
          </Alert>
          <Card style={{ marginTop: "50px" }}>
            <CardHeader color="primary">
              <h4> Muestras publicas Base de datos (SEEP) </h4>
              <p>Muestras subidas por diferentes usuarios</p>
            </CardHeader>
            <CardBody>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* <FormControl style={{ width: "25%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Grupo
                  </InputLabel>
                  <Select
                    value={filtros.grupo}
                    style={{ width: "100%" }}
                    id="grupos_id"
                    onChange={(e) =>
                      setFiltros({ ...filtros, grupo: e.target.value })
                    }
                  >
                    {grupos.map((grupo) => (
                      <MenuItem value={grupo.id}>{grupo.grupo}</MenuItem>
                    ))}
                  </Select>
                </FormControl> */}

                <FormControl style={{ width: "25%" }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Calidad de Análisis
                  </InputLabel>
                  <Select
                    value={filtros.tipo}
                    label="Filtrar por tipos"
                    style={{ width: "100%" }}
                    labelId="label-tipos"
                    id="tipos_id"
                    placeholder="Filtra por tipos"
                    onChange={(e) =>
                      setFiltros({ ...filtros, tipo: e.target.value })
                    }
                  >
                    {tipos.map((tipo) => (
                      <MenuItem value={tipo.id}>{tipo.tipo}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  id="date"
                  defaultValue={filtros.inicio_fecha_recogida}
                  label="Inicio Fecha Recogida"
                  type="date"
                  className={classes.textField}
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      inicio_fecha_recogida: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="date"
                  defaultValue={filtros.final_fecha_recogida}
                  label="Final Fecha Recogida"
                  type="date"
                  className={classes.textField}
                  onChange={(e) =>
                    setFiltros({
                      ...filtros,
                      final_fecha_recogida: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setFiltros(defaultFiltros)}
                >
                  Borrar Filtros <ClearIcon />
                </Button>
              </GridItem>

              <GridItem
                style={{
                  width: "100%",
                  marginTop: "10px",
                  justifyContent: "end",
                  display: "flex",
                }}
              >
                {contadorFilasSeleccionadas > 1 && (
                  <>
                    <Button
                      style={{ marginRight: "5px", background: "#6eb53f" }}
                      variant="contained"
                      color="primary"
                      onClick={() => loadDetailsMuestras()}
                    >
                      Consultar Filas Seleccionadas {""} <PageviewIcon />
                    </Button>

                    <Button
                      style={{ marginRight: "5px", background: "#6eb53f" }}
                      variant="contained"
                      color="primary"
                      onClick={() => exportarArchivos()}
                    >
                      Exportar Filas Seleccionadas {""} <ImportExportIcon />
                    </Button>
                  </>
                )}
                {/* 
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setFiltros(defaultFiltros)}
                >
                  Borrar Filtros
                </Button> */}
              </GridItem>

              <div className={classes.root}>
                <Datatable
                  checkboxFiltroNom={true}
                  selectableRows={true}
                  data={muestras}
                  activarFiltro={true}
                  handleFiltroNomLatino={handleFiltroNomLatino}
                  handleRowSelected={handleRowSelected}
                  columnNames={columnNames}
                  search={(data) => handleSearch(data)}
                  load={isLoad}
                  loader={
                    <GridContainer
                      style={{ width: "100%", height: "300px" }}
                      direction="row"
                      alignItems="center"
                      justify="center"
                    >
                      <CustomLoading color={PARAMS.firstColor} size={80} />
                    </GridContainer>
                  }
                  serverSide={true}
                  totalRows={totalData}
                  perPage={perPageData}
                  handlePageChange={(page) => {
                    setActualPage(page);
                  }}
                  handlePerRowsChange={(perPage, page) => {
                    setPerPageData(perPage);
                    setActualPage(page);
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </GridItem>

        {/* VER DETALLE MUESTRA */}
        <Modal
          open={openModal}
          onCancel={() => handleClose()}
          confirmText={"Eliminar"}
          content={<DetalleMuestra filtros={filtros} />}
          title="Detalle de la muestra"
          // onConfirm={() => eliminarMuestra()}
        />
        {/* DESHABILITAR/HABILITAR MUESTRA */}
        <Modal
          open={openDeshabilitar}
          onCancel={() => handleClose()}
          confirmText={"Deshabilitar"}
          content={<h3>¿Esta seguro de deshabilitar esta muestra?</h3>}
          title="Deshabilitar Muestra"
          onConfirm={() => deshabilitarMuestra()}
        />
        <Modal
          open={openHabilitar}
          onCancel={() => handleClose()}
          confirmText={"Habilitar"}
          content={<h4>¿Esta seguro de habilitar esta muestra?</h4>}
          title="Habilitar Muestra"
          onConfirm={() => habilitarMuestra()}
        />
        <Modal
          open={openLogin}
          onCancel={() => handleClose()}
          confirmText={"Habilitar"}
          content={alertLogin()}
          noBtn={true}
          // title="Habilitar Muestra"
          onConfirm={() => habilitarMuestra()}
        />
      </GridContainer>
    </>
  );
};

export default Buscador;
