export const CREAR_RESERVA_ERROR = "CREAR_RESERVA_ERROR";
export const CREAR_RESERVA_EXITO = "CREAR_RESERVA_EXITO";
export const CREAR_RESERVA_NUEVO_CLIENTE_EXITO =
  "CREAR_RESERVA_NUEVO_CLIENTE_EXIITO";

export const ACTUALIZAR_LIBRO_RESERVA_EXITO = "ACTUALIZAR_LIBRO_RESERVA_EXITO";
export const ACTUALIZAR_LIBRO_RESERVA_ERROR = "ACTUALIZAR_LIBRO_RESERVA_ERROR";

export const FILTROS_APLICADOS_EXITO = "FILTROS_APLICADOS_EXITO";
export const FILTROS_APLICADOS_ERROR = "FILTROS_APLICADOS_ERROR";
