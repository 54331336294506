import { Alert, AlertTitle } from "@material-ui/lab";
import Actions from "components/Actions/Actions";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { detalleMuestra } from "utils/API_V2";
import Datatable from "components/Table/Datatable";
import CustomLoading from "components/CustomLoading/CustomLoading";
import PARAMS from "utils/PARAMS";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";
import { formatoFiltros, incrementarNumConsulta } from "utils/API_V2";
import { exportarAnalisis } from "utils/API_V2";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import * as FileSaver from "file-saver";
import { Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    // display: "none",
  },
}));

const DetalleMuestra = (props) => {
  const classes = useStyles();
  const muestraId = props.match.params.muestraId;
  const [analisis, setAnalisis] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [page, setPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [findBy, setFindBy] = useState("");
  const [actualPage, setActualPage] = useState(1);
  const [filtrosAplicados, setFiltrosAplicados] = useState({});
  let history = useHistory();

  const columnNames = [
    { name: "Análisis", key: "analisis", width: "400px", sortable: false },
    { name: "Uds", key: "unidades" },
    { name: "Reusultado", key: "resultado" },
    { name: "Acciones", key: "acciones", width: "200px", sortable: false },
    // { name: "Nº", key: "numero" },
    // { name: "Media", key: "media" },
    // { name: "Mínimo", key: "minimo" },
    // { name: "Máximo", key: "maximo" },
    // { name: "Dv. Típica", key: "dv_tipica" },
  ];

  function createData(
    analisis,
    unidades,
    resultado,
    acciones
    // numero,
    // media,
    // minimo,
    // maximo,
    // dv_tipica,
  ) {
    return {
      analisis,
      unidades,
      resultado,
      acciones,
      // numero,
      // media,
      // minimo,
      // maximo,
      // dv_tipica,
    };
  }

  useEffect(() => {
    // actualizarNumConsulta();
  }, []);

  async function actualizarNumConsulta() {
    const res = await incrementarNumConsulta(muestraId);

    if (res.error) {
      toast("Error al intener actualizar el número de consultas", {
        type: "error",
      });
    }
  }

  async function descargarAnalisis(id) {
    const res = await exportarAnalisis(id);

    if (res.error) {
      toast("Error al intentar descargar el análisis", { type: "error" });
    } else {
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });

      FileSaver.saveAs(blob, "Analisis.xlsx");
    }
  }

  const handleSearch = (findBy) => {
    // obtenerDetallesMuestra(findBy, page, perPageData, filtrosAplicados);
  };

  function handleClick(path) {
    history.push(path);
  }

  const formateoFiltros = () => {
    let auxFiltrosAplicados = JSON.parse(
      localStorage.getItem("filtrosAplicados")
    );
    obtenerFiltrosAplicados(auxFiltrosAplicados);
  };

  async function obtenerFiltrosAplicados(filtrosAplicados) {
    const res = await formatoFiltros(filtrosAplicados);

    if (res.error) {
      toast("Error, al obtener los filtros aplicados", { type: "error" });
    } else {
      setFiltrosAplicados(res.data);
    }
  }

  const handleClickGrafico = (analisiId) => {
    history.push(`/grafico/${analisiId}`);
  };

  useEffect(() => {
    formateoFiltros();
    obtenerDetallesMuestra();
  }, []);

  async function obtenerDetallesMuestra() {
    setIsLoad(false);
    const res = await detalleMuestra(findBy, page, perPageData, muestraId);

    if (res.error) {
      toast("Se ha producido un error en la carga de analisis", {
        type: "warning",
      });
    } else {
      const arrayAnalisis = [];
      if (res.data.data.length > 0) {
        res.data.data.forEach((analisi, index) => {
          let aux = createData(
            analisi.analisis,
            analisi.unidades,
            analisi.resultado,
            <div>
              <Actions
                show={true}
                onShow={() => handleClickGrafico(analisi.id)}
              />
            </div>
          );
          arrayAnalisis.push(aux);
        });
      }
      setTotalData(res.data.meta.total);
      setAnalisis(arrayAnalisis);
      setIsLoad(true);
    }
  }

  return (
    <>
      <GridContainer xs={12} sm={12} md={12} lg={12} xl={12}>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            xs={8}
            sm={8}
            md={8}
            lg={8}
            xl={8}
          >
            <Link
              color="inherit"
              onClick={() => handleClick("/buscador")}
              style={{ cursor: "pointer" }}
            >
              Buscador
            </Link>
            <Typography color="textPrimary">
              Análisis de la muestra {muestraId}
            </Typography>
          </Breadcrumbs>
          <Button
            xs={4}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            variant="contained"
            style={{ fontSize: "18px" }}
            onClick={() => history.goBack()}
          >
            <ArrowBackIosIcon /> VOLVER ATRÁS
          </Button>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
          <Alert severity="info">
            <AlertTitle>
              <strong>
                Si se desea conocer la fuente de información original, dirigir
                una consulta a infosia@uco.es , indicando muestras y parámetros
                de búsqueda seleccionados.
              </strong>
            </AlertTitle>
            <strong> 1) GRUPO DE ANÁLISIS: </strong> {filtrosAplicados?.grupo}
            <br></br>
            <strong> 2) TIPO DE ANÁLISIS: </strong> {filtrosAplicados?.tipo}
            <br></br>
            <strong> 2) FECHA DE RECOGIDA: </strong>Entre el{" "}
            {filtrosAplicados?.fecha_inicio} y el{" "}
            {filtrosAplicados?.fecha_final}
            <br></br>
            <strong> 2) MUESTRAS SELECCIONADAS: </strong>
            {filtrosAplicados?.muestras?.map((muestra) => (
              <span>{muestra + " , "}</span>
            ))}
          </Alert>
          <Card style={{ marginTop: "50px" }}>
            <CardHeader color="primary">
              <h4> Analisis de la muestra </h4>
              <p>Analisis de la muestra seleccionada</p>
            </CardHeader>
            <CardBody>
              <div className={classes.root}>
                <Datatable
                  data={analisis}
                  columnNames={columnNames}
                  search={(data) => handleSearch(data)}
                  load={isLoad}
                  loader={
                    <GridContainer
                      style={{ width: "100%", height: "300px" }}
                      direction="row"
                      alignItems="center"
                      justify="center"
                    >
                      <CustomLoading color={PARAMS.firstColor} size={80} />
                    </GridContainer>
                  }
                  serverSide={true}
                  totalRows={totalData}
                  perPage={perPageData}
                  handlePageChange={(page) => {
                    setActualPage(page);
                  }}
                  handlePerRowsChange={(perPage, page) => {
                    setPerPageData(perPage);
                    setActualPage(page);
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default DetalleMuestra;
